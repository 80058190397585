import React from "react";
import * as style from "./CreateBuckets.style";
import { classes } from "typestyle";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../../gql";
import { connect, useDispatch } from "react-redux";
import { ContestActions } from "../../../store/actions";
import * as Models from "../../../models";
import { RootState } from "../../../store/reducers";
import {
  CreateBucketActions,
  CreateBucketsState,
} from "./CreateBuckets.reducer";
import * as Components from "../../../components";
import * as Utility from "../../../utility";

interface ComponentProps {
  state: CreateBucketsState;
  localDispatch: React.Dispatch<CreateBucketActions>;
}

interface StateProps {
  matchups: Record<number, Models.Contests.Matchup> | null;
}

export const CreateBucketsInternal: React.FC<ComponentProps & StateProps> = ({
  matchups,
  state,
  localDispatch,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const matchupsArray = React.useMemo(() => {
    return matchups
      ? Object.values(matchups).sort(
          (matchupA, matchupB) =>
            new Date(matchupA.eventStartTime).getTime() -
            new Date(matchupB.eventStartTime).getTime(),
        )
      : [];
  }, [matchups]);

  const getMatchups = (skip: number) => {
    client
      .query({
        query: Query.GET_MATCHUPS_QUERY,
        fetchPolicy: "no-cache",
        variables: {
          take: 50,
          skip,
          eventStartTimeGreaterThan: new Date(),
          status: Models.Contests.MatchupStatus.PENDING,
        },
      })
      .then((res) => {
        if (res?.data?.adminMatchups?.length) {
          dispatch(
            ContestActions.getMatchupsSuccess({
              matchups: res.data.adminMatchups,
            }),
          );
        }
      })
      .catch((res) => {
        console.log("failed", res);
      });
  };

  React.useEffect(() => {
    getMatchups(0);
  }, []);

  /**
   * Values
   */

  const bucketsArray = React.useMemo(() => {
    return Object.values(state.buckets);
  }, [state.buckets]);

  React.useEffect(() => {
    localDispatch({ type: "CREATE_NEW_BUCKET" });
  }, []);

  // React.useEffect(() => {
  //   console.log(state);
  //   // console.log(matchupsArray);
  // }, [state]);

  return (
    <div className={style.main}>
      <div className={style.title} style={{ fontSize: 32 }}>
        Create Buckets
      </div>
      <div className={style.topNav}>
        {bucketsArray.map(
          (bucket, i) =>
            !bucket.isDeleted && (
              <div
                className={classes(
                  style.topNavItem,
                  state.currentIndex === i ? style.activeTopNavItem : "",
                )}
                key={i}
                onClick={() => {
                  localDispatch({
                    type: "UPDATE_CURRENT_INDEX",
                    payload: { newIndex: i },
                  });
                }}
              >
                {!!bucket.name ? bucket.name : `Bucket ${i + 1}`}
              </div>
            ),
        )}
      </div>
      {state.buckets[state.currentIndex] && (
        <>
          <div className={style.formWrapper}>
            <div className={style.formSection}>
              <Components.TextInput
                className={style.textInput}
                value={state.buckets[state.currentIndex].name}
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_FIELD",
                    payload: {
                      bucketId: state.currentIndex,
                      field: "name",
                      value: e,
                    },
                  })
                }
                label="Name"
                error={state.buckets[state.currentIndex].errors["name"]}
                autocomplete="off"
              />
              <Components.TextInput
                className={style.textInput}
                value={state.buckets[state.currentIndex].description}
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_FIELD",
                    payload: {
                      bucketId: state.currentIndex,
                      field: "description",
                      value: e,
                    },
                  })
                }
                label="Description"
                error={state.buckets[state.currentIndex].errors["description"]}
              />
              <Components.DateTimePicker
                wrapperClassName={style.input}
                className={style.textInput}
                value={
                  state.buckets[state.currentIndex].selectionWindowStartsAtUtc
                }
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_FIELD",
                    payload: {
                      bucketId: state.currentIndex,
                      field: "selectionWindowStartsAtUtc",
                      value: e,
                    },
                  })
                }
                label="Selection Window Start Time"
                error={
                  state.buckets[state.currentIndex].errors[
                    "selectionWindowStartsAtUtc"
                  ]
                }
              />
              <Components.DateTimePicker
                wrapperClassName={style.input}
                className={style.textInput}
                value={
                  state.buckets[state.currentIndex].selectionWindowEndsAtUtc
                }
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_FIELD",
                    payload: {
                      bucketId: state.currentIndex,
                      field: "selectionWindowEndsAtUtc",
                      value: e,
                    },
                  })
                }
                label="Selection Window End Time"
                error={
                  state.buckets[state.currentIndex].errors[
                    "selectionWindowEndsAtUtc"
                  ]
                }
              />
              <Components.TextInput
                className={style.textInput}
                value={state.buckets[
                  state.currentIndex
                ].minSelection.toString()}
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_FIELD",
                    payload: {
                      bucketId: state.currentIndex,
                      field: "minSelection",
                      value: Number.parseInt(e),
                    },
                  })
                }
                label="Selections Required"
                inputType="number"
                error={state.buckets[state.currentIndex].errors["minSelection"]}
              />
              <Components.TextInput
                className={style.textInput}
                value={state.buckets[state.currentIndex].sortOrder.toString()}
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_FIELD",
                    payload: {
                      bucketId: state.currentIndex,
                      field: "sortOrder",
                      value: Number.parseInt(e),
                    },
                  })
                }
                label="Sort Order"
                inputType="number"
                error={state.buckets[state.currentIndex].errors["sortOrder"]}
              />
              {/* <Components.TextInput
                className={style.textInput}
                value={state.buckets[
                  state.currentIndex
                ].maxSelection.toString()}
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_FIELD",
                    payload: {
                      bucketId: state.currentIndex,
                      field: "maxSelection",
                      value: Number.parseInt(e),
                    },
                  })
                }
                label="Max Selection"
                inputType="number"
                error={state.buckets[state.currentIndex].errors["maxSelection"]}
              /> */}
            </div>
            <div className={style.formSection}>
              <div className={style.subtitle}>Matchups</div>
              <div className={style.matchupSelectionWrapper}>
                {matchupsArray.map((matchup) => (
                  <div
                    key={matchup.id}
                    className={classes(
                      style.matchup,
                      bucketsArray[
                        state.currentIndex
                      ].preexistingMatchupIds.includes(matchup.id)
                        ? style.selectedMatchup
                        : "",
                    )}
                    onClick={() =>
                      localDispatch({
                        type: "MATCHUP_CLICKED",
                        payload: { matchupId: matchup.id },
                      })
                    }
                  >
                    <div>
                      <div>{`${matchup.name}`}</div>
                      <div
                        className={style.matchupDate}
                      >{`${Utility.date.toShortDateAndTimeUTC(
                        new Date(matchup.eventStartTime),
                      )}`}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      {
        <Components.Button
          className={style.button}
          label={"Delete Bucket"}
          onClick={() =>
            localDispatch({
              type: "DELETE_BUCKET",
              payload: { bucketIndex: state.currentIndex },
            })
          }
          disabled={bucketsArray.length === 1}
        />
      }
      <Components.Button
        className={style.button}
        label={"Add Bucket"}
        onClick={() => localDispatch({ type: "CREATE_NEW_BUCKET" })}
      />
    </div>
  );
};

export const CreateBuckets = connect((state: RootState) => ({
  matchups: state.contests.matchups,
}))(CreateBucketsInternal);
