import React from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../../gql";
import { Icon } from "../../../components";
import * as style from "./ResultMatchups.style";
import * as Models from "../../../models";
import { toast } from "react-toastify";
import { MatchupStatus } from "../../../models/contest";
import * as Components from "../../../components";
import { toShortDateAndTimeUTC } from "../../../utility/date";

export const ResultMatchups: React.FC = () => {
  const client = useApolloClient();
  const [matchupsArr, setMatchupsArr] = React.useState<
    Models.Contests.Matchup[] | null
  >(null);
  const [selectedMatchup, setSelectedMatchup] =
    React.useState<Models.Contests.Matchup | null>(null);
  const [inProgress, setInProgress] = React.useState<boolean>(false);
  const [isVoidSelected, setIsVoidSelected] = React.useState<boolean>(false);
  const [selectedMatchupOptionId, setSelectedMatchupOptionId] = React.useState<
    number | null
  >(null);
  const [showAllMatchups, setShowAllMatchups] = React.useState(false);

  const selectedMatchupOption = React.useMemo(() => {
    if (!!selectedMatchup && !!selectedMatchupOptionId) {
      return selectedMatchup.matchupOptions.find(
        (opt) => opt.matchupOption.id === selectedMatchupOptionId,
      );
    }
  }, [selectedMatchup, selectedMatchupOptionId]);

  const getMatchups = (showAll: boolean) => {
    client
      .query({
        query: Query.GET_MATCHUPS_QUERY,
        variables: {
          take: 50,
          skip: 0,
          ...(showAll ? {} : { eventStartTimeLessThan: new Date() }),
          status: MatchupStatus.PENDING,
        },
      })
      .then((res) => {
        if (res?.data?.adminMatchups) {
          setMatchupsArr(res.data.adminMatchups);
        }
      })
      .catch((res) => {
        toast.error("Issue getting Matchups");
      });
  };

  React.useEffect(() => {
    getMatchups(showAllMatchups);
  }, [selectedMatchup, showAllMatchups]);

  React.useEffect(() => {
    setSelectedMatchup(null);
  }, [showAllMatchups]);

  const [resultMatchupMutation, resultMatchupMutationStatus] = useMutation(
    Mutation.RESULT_MATCHUP_MUTATION,
  );
  const resultMatchup = (matchupId: number, matchupOptionId: number) => {
    setInProgress(true);
    resultMatchupMutation({
      variables: {
        matchupId,
        matchupOptionId,
      },
    })
      .then((res) => {
        if (res?.data?.adminResultMatchup) {
          setInProgress(false);
          setSelectedMatchup(null);
          setSelectedMatchupOptionId(null);
          toast.success("Success resulting matchup");
        }
      })
      .catch((e) => {
        setInProgress(false);
        setSelectedMatchup(null);
        setSelectedMatchupOptionId(null);
        toast.error("Could not result matchup");
      });
  };

  const [voidMatchupMutation, voidMatchupMutationStatus] = useMutation(
    Mutation.VOID_MATCHUP_MUTATION,
  );
  const voidMatchup = (matchupId: number) => {
    setInProgress(true);
    voidMatchupMutation({
      variables: {
        matchupId,
      },
    })
      .then((res) => {
        if (res?.data?.adminVoidMatchup) {
          setInProgress(false);
          setSelectedMatchup(null);
          setSelectedMatchupOptionId(null);
          setIsVoidSelected(false);
          toast.success("Success voiding matchup");
        }
      })
      .catch((e) => {
        setInProgress(false);
        setSelectedMatchup(null);
        setSelectedMatchupOptionId(null);
        setIsVoidSelected(false);
        toast.error("Could not void matchup");
      });
  };

  return (
    <div>
      {!!matchupsArr ? (
        <div className={style.table}>
          <div className={style.row}>
            <div className={style.rowItemBold}>Name</div>
            <div className={style.rowItemBold}>Event Start Time</div>
            <div className={style.rowItemBold}>Description</div>
            <div className={style.rowItemBold}>Result Matchup</div>
          </div>
          {matchupsArr.map((matchup: Models.Contests.Matchup) => {
            return (
              <div className={style.row} key={matchup.id}>
                <div className={style.rowItem}>{matchup.name}</div>
                <div className={style.rowItem}>
                  {toShortDateAndTimeUTC(new Date(matchup.eventStartTime))}
                </div>
                <div className={style.rowItem}>{matchup.description}</div>
                <div className={style.rowItem}>
                  <Components.Button
                    label="Result"
                    onClick={() => {
                      setSelectedMatchup(matchup);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <Icon.Spinner size={24} />
      )}

      {!!selectedMatchup && (
        <>
          <h1 style={{ textAlign: "center" }}>Matchup</h1>
          <div className={style.table}>
            <div className={style.row}>
              <div className={style.rowItemBold}>{selectedMatchup.name}</div>
              <div className={style.rowItemBold}>
                {toShortDateAndTimeUTC(
                  new Date(selectedMatchup.eventStartTime),
                )}
              </div>
              <div className={style.rowItemBold}>
                {selectedMatchup.description}
              </div>
              <div className={style.rowItem}>
                {new Date(selectedMatchup.eventStartTime) < new Date() && (
                  <>
                    {selectedMatchup.matchupOptions.map((mo) => (
                      <Components.Button
                        label={mo.matchupOption.name}
                        className={style.buttonInTable}
                        onClick={() => {
                          setSelectedMatchupOptionId(mo.matchupOption.id);
                        }}
                      />
                    ))}
                  </>
                )}
                <Components.Button
                  label="Void"
                  className={style.buttonInTable}
                  onClick={() => {
                    setIsVoidSelected(true);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {(!!isVoidSelected || !!selectedMatchupOptionId) && selectedMatchup && (
        <div className={style.modalContainer}>
          <div
            style={{ fontSize: "20px", fontWeight: "bold" }}
            className={style.modalContainerItems}
          >
            {selectedMatchup.name}
          </div>
          <div className={style.modalContainerItems}>
            Description: {selectedMatchup.description}
          </div>
          <div>
            {" "}
            Event Start Time:{" "}
            {toShortDateAndTimeUTC(new Date(selectedMatchup.eventStartTime))}
          </div>
          <div className={style.modalContainerItems}>
            Are you sure you want to{" "}
            {isVoidSelected
              ? `Void ${selectedMatchup.name} matchup?`
              : `result matchup option ${selectedMatchupOption?.matchupOption.name}?`}
          </div>
          <Components.Button
            className={style.modalContainerItems}
            onClick={() => {
              if (isVoidSelected) {
                voidMatchup(selectedMatchup.id);
              } else if (!!selectedMatchupOptionId) {
                resultMatchup(selectedMatchup.id, selectedMatchupOptionId);
              }
            }}
            label="Confirm"
          />
          <Components.Button
            label="Cancel"
            className={style.redButton}
            onClick={() => {
              setIsVoidSelected(false);
              setSelectedMatchupOptionId(null);
            }}
          />
        </div>
      )}
      <Components.Button
        label={
          showAllMatchups
            ? "Show only started matchups"
            : "Include not started matchups"
        }
        className={style.allMatchupsButton}
        onClick={() => {
          setShowAllMatchups(!showAllMatchups);
        }}
      />
    </div>
  );
};
