import { Prize, Reward, Brand } from "./reward";
import { ShakeUser } from "./user";

export interface ContestTemplate {
  id: number;
  createdAtUtc: Date;
  updatedAtUtc: Date;
  status: ContestTemplateStatus;
  name: string;
  description: string;
  viewableStartsAtUtc: Date;
  availableStartsAtUtc: Date;
  availableEndsAtUtc: Date;
  minContestants: number;
  maxContestants: number;
  maxContests?: number;
  tokenEntry: boolean;
  freeEntry: boolean;
  minTokenEntry?: number;
  maxTokenEntry?: number;
  contests: Contest[];
  buckets: Bucket[];
  rewardId?: number;
  reward?: Reward;
  sponsorId?: number;
  sponsor?: Brand;
  sport?: Sport;
}

export interface Contest {
  id: number;
  contestTemplateId: number;
  contestTemplate: ContestTemplate;
  entryType: EntryType;
  tokenEntryAmount?: number;
  tokenRewardAmount?: number;
  rewardId?: number;
  reward?: Reward;
  prizeId?: number;
  prize?: Prize;
  rewardAmount?: number;
  users: ShakeUser[];
  commissionerUserId: number;
  commissioner: ShakeUser;
}

export interface Selection {
  createdAtUtc: Date;
  updatedAtUtc: Date;
  userId: number;
  user: ShakeUser;
  bucketId: number;
  bucket: Bucket;
  matchupId: number;
  matchup: Matchup;
  matchupOptionId: number;
  matchupOption: MatchupOption;
}

export interface Bucket {
  id: number;
  contestTemplateId: number;
  contestTemplate: ContestTemplate;
  matchups: Matchup[];
  name: string;
  description: string;
  selectionWindowStartsAtUtc: Date;
  selectionWindowEndsAtUtc: Date;
  minSelection: number;
  maxSelection: number;
  selections: Selection[];
}
export interface Matchup {
  id: number;
  buckets: Bucket[];
  matchupOptions: {
    matchupOption: MatchupOption;
    matchupOptionVisualization: MatchupOptionVisualization;
  }[];
  name: string;
  description: string;
  eventStartTime: string;
  Sport?: Sport;
  status?: MatchupStatus;
  selections: Selection[];
  resultId?: number;
}
export interface MatchupOption {
  id: number;
  name: string;
  selections: Selection[];
  defaultMatchupOptionVisualizationId: number;
  defaultMatchupOptionVisualization: MatchupOptionVisualization;
  matchups: Matchup[];
}
export interface MatchupOptionVisualization {
  id: number;
  displayName?: string;
  description: string;
  initials?: string;
  initialsBackgroundColor?: string;
  initialsTextColor?: string;
  imageUrl?: string;
  matchupOption: MatchupOption;
  matchupOptions: MatchupOption[];
}

export enum MatchupStatus {
  PENDING = "PENDING",
  SETTLED = "SETTLED",
  VOID = "VOID",
  RESETTLED = "RESETTLED",
}

export enum ContestTemplateStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  DISABLED = "DISABLED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

export enum UserOnContestStatus {
  INVITED = "INVITED",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}
export enum EntryType {
  TOKEN = "TOKEN",
  FREE = "FREE",
}

export enum Sport {
  BASKETBALL = "BASKETBALL",
  FOOTBALL = "FOOTBALL",
  BASEBALL = "BASEBALL",
  TENNIS = "TENNIS",
  GOLF = "GOLF",
  HOCKEY = "HOCKEY",
  SOCCER = "SOCCER",
  UFC = "UFC",
  BOXING = "BOXING",
  FORMULA1 = "FORMULA1",
  RACING = "RACING",
  ENTERTAINMENT = "ENTERTAINMENT",
  MUSIC = "MUSIC",
  AWARDS = "AWARDS",
}
